var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-panel',[_c('ui-flex',[_vm._l((_vm.customerConfigurations),function(ref){
var customer = ref.customer;
var configurations = ref.configurations;
return _c('ui-cell',{key:customer.id,attrs:{"basis":1}},[_c('ui-form',{attrs:{"action":function () { return _vm.configureNotificationEmails(customer); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel-header',{attrs:{"title":customer.name}},[_c('ui-wizard-button',{attrs:{"busy":busy,"secondary":""},on:{"click":submit}},[_vm._v("Kundenkonfiguration entfernen")])],1)],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-table',{attrs:{"columns":_vm.columns,"rows":configurations,"align":"left"},scopedSlots:_vm._u([{key:"interval",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.notificationIntervalLabels[value]))]}},{key:"gatewayHealths",fn:function(ref){
var value = ref.value;
return [(value.length > 0)?_vm._l((value),function(health){return _c('domain-ui-gateway-health',{key:health,attrs:{"health":health,"capitalize":""}})}):[_vm._v("–")]]}},{key:"spotHealths",fn:function(ref){
var value = ref.value;
return [(value.length > 0)?_vm._l((value),function(health){return _c('domain-ui-spot-health',{key:health,attrs:{"health":health,"capitalize":""}})}):[_vm._v("–")]]}},{key:"alertCategories",fn:function(ref){
var value = ref.value;
return [(value.length > 0)?_vm._l((value),function(category){return _c('div',{key:category.id},[_vm._v(_vm._s(category.name))])}):[_vm._v("–")]]}},{key:"missedActivityLogEntry",fn:function(ref){
var value = ref.value;
return [(value === true)?[_vm._v("Ja")]:[_vm._v("Nein")]]}},{key:"heatingSystem",fn:function(ref){
var value = ref.value;
return [(value === true)?[_vm._v("Ja")]:[_vm._v("Nein")]]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('notification-email-configuration-modal',{attrs:{"customer":customer,"configuration":row,"apply-action":function (data, param) { return _vm.configureNotificationEmails(customer, row.interval, param === 'clear' ? undefined : data); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-link',{attrs:{"disabled":busy},on:{"click":show}},[_vm._v("Ändern")])]}}],null,true)})]}}],null,true)})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-panel-separator')],1)],1)]}}],null,true)})],1)}),(_vm.availableCustomers.length > 0)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-input-select',{attrs:{"value":null,"options":_vm.availableCustomers,"clearable":false,"label":"name","placeholder":"Kunden zur Konfiguration auswählen…"},on:{"update":_vm.addCustomerConfigurations}})],1):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }