







































































import { GATEWAY_HEALTH_META } from '@/features/domain-ui/gateway-health/constants';
import { SPOT_HEALTH_META } from '@/features/domain-ui/spot-health/constants';
import { Option } from '@/features/ui/inputs/model';
import { GatewayHealth, NotificationInterval, SpotHealth } from '@/types/iot-portal';
import { FunctionProp, ObjectProp } from '@/util/prop-decorators';
import { at } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { ApplyAction, Customer, NOTIFICATION_INTERVAL_LABELS, NotificationEmailConfiguration } from './model';

function pluckIds<T>(objects: { id: T }[] | null): T[] {
  return objects === null ? [] : objects.map(({ id }) => id);
}

@Component
export default class NotificationEmailConfigurationModal extends Vue {
  @ObjectProp(true)
  private readonly customer!: Customer;
  @ObjectProp(true)
  private readonly configuration!: NotificationEmailConfiguration;
  @FunctionProp()
  private readonly applyAction?: ApplyAction;

  private readonly pluckIds = pluckIds;
  private readonly notificationIntervalLabels = NOTIFICATION_INTERVAL_LABELS;

  private get smsNotificationsEnabled(): boolean {
    return this.configuration.interval === NotificationInterval.IMMEDIATELY;
  }

  private get gatewayHealthOptions(): Option[] {
    return at(GATEWAY_HEALTH_META, GatewayHealth.CRITICAL, GatewayHealth.DEGRADED);
  }

  private get spotHealthOptions(): Option[] {
    return at(SPOT_HEALTH_META, SpotHealth.CRITICAL, SpotHealth.DEGRADED);
  }
}
