import { FormAction } from '@/features/ui/form/model';
import { ConfigureNotificationEmailsForUserInput, NotificationInterval } from '@/types/iot-portal';
import { AppUserAccountAppNotificationSettingsViewNotificationEmailConfigurationFragment } from './__generated__/AppUserAccountAppNotificationSettingsViewNotificationEmailConfigurationFragment';
import { AppUserAccountAppNotificationSettingsViewQuery } from './__generated__/AppUserAccountAppNotificationSettingsViewQuery';

export type NotificationEmailConfiguration =
  AppUserAccountAppNotificationSettingsViewNotificationEmailConfigurationFragment;
export type Customer = AppUserAccountAppNotificationSettingsViewQuery['customers']['items'][number];
export interface CustomerNotificationEmailConfigurations {
  customer: Customer;
  configurations: NotificationEmailConfiguration[];
}

export type EditableFields = Pick<
  ConfigureNotificationEmailsForUserInput['configurations'][number],
  'spotHealths' | 'gatewayHealths' | 'alertCategoryIds' | 'missedActivityLogEntry' | 'heatingSystem'
>;
export type ApplyAction = FormAction<EditableFields, void, string | undefined>;

export const NOTIFICATION_INTERVAL_LABELS = Object.freeze({
  [NotificationInterval.IMMEDIATELY]: 'Sofort',
  [NotificationInterval.DAILY]: 'Täglich',
  [NotificationInterval.WEEKLY]: 'Wöchentlich',
} as const);

export const ORDERED_INTERVALS = Object.freeze([
  NotificationInterval.IMMEDIATELY,
  NotificationInterval.DAILY,
  NotificationInterval.WEEKLY,
] as const);

export function createDefaultConfiguration(
  interval: NotificationInterval,
  customer: NotificationEmailConfiguration['customer'],
): NotificationEmailConfiguration {
  return {
    __typename: 'NotificationEmailConfiguration',
    id: '',
    version: 0,
    interval,
    customer,
    alertCategories: [],
    gatewayHealths: [],
    spotHealths: [],
    missedActivityLogEntry: false,
    heatingSystem: false,
    enabled: false,
    smsNotificationsEnabled: false,
  };
}
