import { GroupRole } from '@/types/iot-portal';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import GroupsMixin from './groups';

type AppOrderRedirection = { [key in GroupRole]?: string };

@Component
export default class AppRedirectionMixin extends Mixins(GroupsMixin) {
  private get appOrderRedirection(): AppOrderRedirection {
    return {
      [GroupRole.APP_HOME]: 'AppHome',
      [GroupRole.APP_MANAGER]: 'AppManager',
      [GroupRole.APP_CUSTOMER]: 'AppCustomer',
      [GroupRole.APP_HEATING_SYSTEM]: 'AppHeatingSystem',
      [GroupRole.APP_SMOKE_DETECTOR]: 'AppSmokeDetector',
      [GroupRole.APP_ACT_AQUA]: 'AppActAqua',
      [GroupRole.APP_ADMIN]: 'AppAdmin',
      [GroupRole.APP_MAINTENANCE]: 'AppMaintenance',
      [GroupRole.APP_EXPORT]: 'AppExport',
      [GroupRole.APP_USER_ACCOUNT]: 'AppUserAccount',
      [GroupRole.APP_HELP]: 'AppHelp',
      [GroupRole.APP_SALES]: 'AppSales',
      [GroupRole.APP_EED]: 'AppEED',
      [GroupRole.APP_NOTIFICATION]: 'AppNotification',
      [GroupRole.APP_SUPPORT]: 'AppSupport',
      [GroupRole.APP_SUITE_FIVE]: 'AppSuiteFive',
      [GroupRole.APP_ENTERPRISE_PROJECT]: 'AppEnterpriseProject',
    };
  }

  @Watch('groups')
  private async checkAppRedirect(): Promise<void> {
    const routeApp = this.$route.meta?.app as GroupRole | undefined;

    if (routeApp && this.hasApp(routeApp)) {
      return;
    }

    for (const [app, redirectTo] of Object.entries(this.appOrderRedirection)) {
      if (this.hasApp(app as GroupRole)) {
        await this.$router.push({ name: redirectTo });

        return;
      }
    }

    await this.$router.push({ name: 'NotAllowed' });
  }
}
