



















import AppRedirectionMixin from '@/features/core/components/mixins/app-redirection';
import GroupsMixin from '@/features/core/components/mixins/groups';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class AppView extends Mixins(AppRedirectionMixin, GroupsMixin) {}
