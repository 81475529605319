import { GatewayHealth } from '@/types/iot-portal';
import { stripIndent } from 'common-tags';

export interface GatewayHealthMeta {
  value: GatewayHealth;
  color: string;
  label: string;
  description?: string;
}

export const GATEWAY_HEALTH_META: { readonly [k in GatewayHealth]: Readonly<GatewayHealthMeta> } = Object.freeze({
  [GatewayHealth.UNKNOWN]: {
    value: GatewayHealth.UNKNOWN,
    color: '#4a4d4e',
    label: 'unbekannt',
    description: 'Bisher wurden keine Daten von diesem Gateway empfangen.',
  },
  [GatewayHealth.CRITICAL]: {
    value: GatewayHealth.CRITICAL,
    color: '#fe004f',
    label: 'kritisch',
    description: stripIndent`
      Eine Wartung ist notwendig, da seit längerer Zeit keine Daten von diesem
      Gateway empfangen wurden.
    `,
  },
  [GatewayHealth.DEGRADED]: {
    value: GatewayHealth.DEGRADED,
    color: '#f5a623',
    label: 'eingeschränkt',
    description: stripIndent`
      Eine Wartung wird möglicherweise notwendig, da seit kurzem keine Daten von
      diesem Gateway empfangen werden.
    `,
  },
  [GatewayHealth.OK]: { value: GatewayHealth.OK, color: '#66cd05', label: 'störungsfrei' },
});
