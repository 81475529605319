var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-wizard',{attrs:{"title":((_vm.customer.name) + " – " + (_vm.notificationIntervalLabels[_vm.configuration.interval]))},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._t("default",null,null,props)]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-form',{attrs:{"initial-data":_vm.configuration,"action":_vm.applyAction},on:{"submitted":hide},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
var busy = ref.busy;
var submit = ref.submit;
var errorMessages = ref.errorMessages;
return [_c('core-toast',{attrs:{"messages":errorMessages,"error":""}}),_c('ui-flex',{staticStyle:{"max-width":"540px"}},[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","multiple":"","path":"gatewayHealths","label":"Gatewayzustand","options":_vm.gatewayHealthOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
}}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","multiple":"","path":"spotHealths","label":"Gerätezustand","options":_vm.spotHealthOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
}}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-select","multiple":"","initial-path":"alertCategories","path":"alertCategoryIds","label":"Alarmkategorien","transformer":_vm.pluckIds,"options":_vm.customer.alertCategories.items,"option-label":"name"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-switch","path":"missedActivityLogEntry","label":"Verpasste Aktivitäten"}})],1),_c('ui-cell',{attrs:{"basis":1}},[_c('ui-form-field',{attrs:{"input":"ui-input-switch","path":"heatingSystem","label":"Heizungssysteme"}}),(_vm.smsNotificationsEnabled)?[_c('ui-cell',{attrs:{"basis":1}}),_c('ui-form-field',{attrs:{"input":"ui-input-switch","initial-path":"smsNotificationsEnabled","path":"smsNotificationsEnabled","label":"SMS-Benachrichtigungen","option-label":"Aktiviert"}})]:_vm._e()],2),_c('ui-cell',{attrs:{"grow":0}},[_c('ui-wizard-button',{attrs:{"secondary":"","busy":busy},on:{"click":function($event){return submit('clear')}}},[_vm._v("Benachrichtigungsintervall deaktivieren")])],1),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{attrs:{"disabled":!dirty,"busy":busy},on:{"click":submit}},[_vm._v("Speichern")])],1)],1)]}}],null,true)})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }