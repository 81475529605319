

























import ContainerMixin from '@/features/core/components/mixins/container';
import { Action, IdToken, RootAction, RootState } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { EditMyEmailInput, EditMyPersonalDataInput, EditMyPhoneNumberInput } from '@/types/iot-portal';
import { Component, Mixins } from 'vue-property-decorator';
import editPersonalDataMutation from './edit-personal-data.gql';
import resetUserPasswordMutation from './reset-password.gql';
import query from './view.gql';
import { AppUserAccountGeneralViewEditPersonalDataMutation } from './__generated__/AppUserAccountGeneralViewEditPersonalDataMutation';
import { AppUserAccountGeneralViewQuery } from './__generated__/AppUserAccountGeneralViewQuery';
import { AppUserAccountGeneralViewResetPasswordMutation } from './__generated__/AppUserAccountGeneralViewResetPasswordMutation';

@Component({
  apollo: {
    me: {
      query,
      fetchPolicy: 'cache-and-network',
    },
  },
})
export default class GeneralView extends Mixins(ContainerMixin) {
  @RootState
  private readonly idToken?: IdToken;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private me?: AppUserAccountGeneralViewQuery['me'];

  private async editPersonalData({
    editMyPersonalDataInput,
    editMyEmailDataInput,
    editMyPhoneNumberDataInput,
  }: {
    editMyPersonalDataInput: EditMyPersonalDataInput;
    editMyEmailDataInput?: EditMyEmailInput;
    editMyPhoneNumberDataInput?: EditMyPhoneNumberInput;
  }): Promise<void> {
    const { data, errors } = await this.$apollo.mutate<AppUserAccountGeneralViewEditPersonalDataMutation>({
      mutation: editPersonalDataMutation,
      variables: {
        editMyPersonalDataInput,
        editMyEmailDataInput,
        editMyPhoneNumberDataInput,
      },
    });

    if (data && !errors?.length) {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Änderungen erfolgreich gespeichert.' }],
      });
    }
  }

  private async resetPassword(): Promise<void> {
    if (!this.me) {
      return;
    }

    if (window.confirm('Möchten Sie das Passwort wirklich zurücksetzen?')) {
      const { data, errors } = await this.$apollo.mutate<AppUserAccountGeneralViewResetPasswordMutation>({
        mutation: resetUserPasswordMutation,
        variables: {
          resetPasswordInput: {
            email: this.me.email,
          },
        },
      });

      if (data && !errors?.length) {
        this.ADD_TOAST_MESSAGES({
          messages: [{ text: 'Die Änderungen konnten nicht gespeichert werden.' }],
        });
      }
    }
  }
}
