



































































import { Column } from '@/features/ui/table/model';
import { NotificationInterval } from '@/types/iot-portal';
import { ApolloQueryResult } from 'apollo-client';
import { groupBy, keyBy } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import {
  AppUserAccountAppNotificationSettingsViewConfigureNotificationEmailsMutation,
  AppUserAccountAppNotificationSettingsViewConfigureNotificationEmailsMutationVariables,
} from './__generated__/AppUserAccountAppNotificationSettingsViewConfigureNotificationEmailsMutation';
import { AppUserAccountAppNotificationSettingsViewQuery } from './__generated__/AppUserAccountAppNotificationSettingsViewQuery';
import configureNotificationEmailsMutation from './configure-notification-emails.gql';
import {
  createDefaultConfiguration,
  Customer,
  CustomerNotificationEmailConfigurations,
  EditableFields,
  NOTIFICATION_INTERVAL_LABELS,
  ORDERED_INTERVALS,
} from './model';
import NotificationEmailConfigurationModal from './NotificationEmailConfigurationModal.vue';
import query from './view.gql';

@Component({
  apollo: {
    view: {
      query,
      manual: true,
      result(this: SettingsView, { data }: ApolloQueryResult<AppUserAccountAppNotificationSettingsViewQuery>): void {
        const configurationsByCustomerId = groupBy(data.notificationEmailConfigurations.items, 'customer.id');
        const customerById = keyBy(data.customers.items, 'id');

        for (const id of Object.keys(configurationsByCustomerId)) {
          this.configuringCustomerSet.add(id);
        }

        const customerConfigurations = [];
        for (const id of this.configuringCustomerSet) {
          const configurationsByInterval = keyBy(configurationsByCustomerId[id] ?? [], 'interval');
          const customer = customerById[id];
          const configurations = ORDERED_INTERVALS.map(
            (interval) => configurationsByInterval[interval] ?? createDefaultConfiguration(interval, customer),
          );

          customerConfigurations.push({ customer, configurations });
        }

        this.customerById = customerById;
        this.customerConfigurations = customerConfigurations;
      },
    },
  },
  components: { NotificationEmailConfigurationModal },
  data() {
    return {
      customerById: {},
      customerConfigurations: [],
      configuringCustomerSet: new Set(),
    };
  },
})
export default class SettingsView extends Vue {
  private customerById!: Record<string, Customer>;
  private customerConfigurations!: CustomerNotificationEmailConfigurations[];
  private configuringCustomerSet!: Set<string>;

  private readonly notificationIntervalLabels = NOTIFICATION_INTERVAL_LABELS;
  private readonly columns: Column[] = [
    { name: 'interval', label: 'Benachrichtigungsintervall' },
    { name: 'gatewayHealths', label: 'Gatewayzustand' },
    { name: 'spotHealths', label: 'Gerätezustand' },
    { name: 'alertCategories', label: 'Alarmkategorien' },
    { name: 'missedActivityLogEntry', label: 'Verpasste Aktivitäten' },
    { name: 'heatingSystem', label: 'Heizungssysteme' },
    { name: 'action', label: '', align: 'right' },
  ];

  private get availableCustomers(): Customer[] {
    return Object.values(
      this.customerConfigurations.reduce(
        (customerById, { customer }) => {
          delete customerById[customer.id];

          return customerById;
        },
        { ...this.customerById },
      ),
    ).sort((a, b) => a.name.localeCompare(b.name));
  }

  private addCustomerConfigurations(customer: Customer | null): void {
    if (!customer) {
      return;
    }

    this.customerConfigurations.push({
      customer,
      configurations: ORDERED_INTERVALS.map((interval) => createDefaultConfiguration(interval, customer)),
    });
    this.configuringCustomerSet.add(customer.id);
  }

  private async configureNotificationEmails(customer: Customer): Promise<void>;
  private async configureNotificationEmails(
    customer: Customer,
    interval: NotificationInterval,
    input?: EditableFields,
  ): Promise<void>;
  private async configureNotificationEmails(
    customer: Customer,
    interval?: NotificationInterval,
    input?: EditableFields,
  ): Promise<void> {
    const configurations = this.customerConfigurations
      .flatMap(({ configurations }) => configurations)
      .filter((configuration) => {
        if (configuration.customer.id !== customer.id) {
          return true;
        }

        return interval !== undefined && configuration.interval !== interval;
      })
      .map(
        ({
          interval,
          customer,
          gatewayHealths,
          spotHealths,
          alertCategories,
          missedActivityLogEntry,
          heatingSystem,
          enabled,
        }) => ({
          interval,
          customerId: customer.id,
          gatewayHealths,
          spotHealths,
          alertCategoryIds: alertCategories.map((alertCategory) => alertCategory.id),
          missedActivityLogEntry,
          heatingSystem,
          enabled,
        }),
      );

    if (interval !== undefined && input !== undefined) {
      configurations.push({
        ...input,
        interval,
        customerId: customer.id,
        enabled:
          input.gatewayHealths.length > 0 ||
          input.spotHealths.length > 0 ||
          input.alertCategoryIds.length > 0 ||
          input.missedActivityLogEntry ||
          input.heatingSystem,
      });
    }

    const { data } = await this.$apollo.mutate<
      AppUserAccountAppNotificationSettingsViewConfigureNotificationEmailsMutation,
      AppUserAccountAppNotificationSettingsViewConfigureNotificationEmailsMutationVariables
    >({
      mutation: configureNotificationEmailsMutation,
      variables: { input: { configurations } },
      update: (store, { data }) => {
        if (data === undefined || data === null) {
          return;
        }

        if (interval === undefined && input === undefined) {
          this.configuringCustomerSet.delete(customer.id);
        }

        const cachedData = store.readQuery<AppUserAccountAppNotificationSettingsViewQuery>({ query });
        if (cachedData === null) {
          return;
        }

        cachedData.notificationEmailConfigurations.items =
          data.configureNotificationEmailsForUser.configurations.filter(({ enabled }) => enabled);
        store.writeQuery({ query, data: cachedData });
      },
    });

    if (!data) {
      throw new Error('Die Konfiguration der E-Mail Benachrichtigungen konnte nicht bearbeitet werden.');
    }
  }
}
